import { FC, useCallback, useLayoutEffect, useRef } from "react";
import Navigation from "../../components/Navigation/Navigation";
import {
  LandingScreenWrapper,
  LandingScreenVideo,
  LandingScreenBackground,
  LandingScreenIntro,
} from "./LandingScreen.sc";

interface Props {
  onVideoLoaded: () => void;
  scrollPosition: number;
}

const LandingScreen: FC<Props> = ({ onVideoLoaded, scrollPosition }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleScroll = useCallback(() => {
    if (scrollPosition > window.innerHeight / 2) {
      try {
        videoRef.current?.pause();
      } catch (error) {
        console.error(error);
      }
    } else if (videoRef.current?.paused) {
      try {
        const isPlaying =
          videoRef.current.currentTime > 0 &&
          !videoRef.current.paused &&
          !videoRef.current.ended &&
          videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;
        if (!isPlaying) {
          const playPromise = videoRef.current?.play();

          playPromise.catch((err) => console.log(err));
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [scrollPosition]);

  useLayoutEffect(() => {
    handleScroll();
  }, [scrollPosition, handleScroll]);

  return (
    <LandingScreenWrapper>
      <Navigation />
      <LandingScreenVideo
        ref={videoRef}
        src={process.env.PUBLIC_URL + "/background.mp4"}
        muted
        autoPlay
        loop
        onLoadedData={onVideoLoaded}
      />
      <LandingScreenBackground />
      <LandingScreenIntro>
        Hello. I'm David.
        <br />A freelance creative software engineer.
      </LandingScreenIntro>
    </LandingScreenWrapper>
  );
};

export default LandingScreen;
