import { FC, useCallback, useLayoutEffect, useState } from "react";
import {
  SkillsScreenWrapper,
  SkillsScreenBackground,
  SkillsScreenBackgroundCube,
  SkillsScreenHeadline,
} from "./SkillsScreen.sc";

interface State {
  isVisible: boolean;
  isHalfway: boolean;
}

interface Props {
  scrollPosition: number;
}

const SkillsScreen: FC<Props> = ({ scrollPosition }: Props) => {
  const [isVisible, setIsVisible] = useState<State["isVisible"]>(false);
  const [isHalfway, setIsHalfway] = useState<State["isHalfway"]>(false);

  const handleScroll = useCallback(() => {
    const newIsVisible =
      scrollPosition >= window.innerHeight * 2.5 &&
      scrollPosition < window.innerHeight * 3.5;

    if (newIsVisible) {
      setIsVisible(true);
      setIsHalfway(scrollPosition >= window.innerHeight * 3);
    } else {
      setIsVisible(false);
      setIsHalfway(false);
    }
  }, [scrollPosition]);

  useLayoutEffect(() => {
    handleScroll();
  }, [scrollPosition, handleScroll]);

  return (
    <SkillsScreenWrapper>
      {scrollPosition > window.innerHeight &&
        scrollPosition < window.innerHeight * 5 && (
          <>
            <SkillsScreenBackground
              $isVisible={isVisible}
              style={{ transform: `scale(${isHalfway ? "0.5" : "1"})` }}
            >
              {Array(10)
                .fill(null)
                .map((_, index) => (
                  <SkillsScreenBackgroundCube
                    key={"cube" + index}
                    style={{
                      animationPlayState: isHalfway ? "paused" : "running",
                    }}
                  />
                ))}
            </SkillsScreenBackground>
            <SkillsScreenHeadline $isVisible={isVisible && !isHalfway}>
              I enjoy building
              <span>playful</span>
              and
              <span>smooth</span>
              user interfaces
            </SkillsScreenHeadline>
            <SkillsScreenHeadline $isVisible={isVisible && isHalfway}>
              and finding calm among chaos.
            </SkillsScreenHeadline>
          </>
        )}
    </SkillsScreenWrapper>
  );
};

export default SkillsScreen;
