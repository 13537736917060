import { styled } from "styled-components";

export const NavigationWrapper = styled.div`
  width: 100vw;
  height: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
  font-size: 2rem;
`;
