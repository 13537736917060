import { FC, useCallback, useLayoutEffect, useState } from "react";
import {
  AboutScreenWrapper,
  AboutScreenCircle,
  AboutScreenHeadline,
} from "./AboutScreen.sc";

export type CurrentSection = null | "first" | "second" | "third" | "finish";

interface State {
  currentSection: CurrentSection;
  scrollPosition: number;
}

interface Props {
  scrollPosition: number;
}

const AboutScreen: FC<Props> = ({ scrollPosition }: Props) => {
  const [currentSection, setCurrentSection] =
    useState<State["currentSection"]>(null);

  const handleScroll = useCallback(() => {
    if (scrollPosition < window.innerHeight) {
      setCurrentSection(null);
      return;
    } else if (scrollPosition >= window.innerHeight * 2.5) {
      setCurrentSection("finish");
      return;
    }

    if (
      scrollPosition >= window.innerHeight &&
      scrollPosition < window.innerHeight * 1.5
    ) {
      setCurrentSection("first");
    } else if (
      scrollPosition >= window.innerHeight * 1.5 &&
      scrollPosition < window.innerHeight * 2
    ) {
      setCurrentSection("second");
    } else if (
      scrollPosition >= window.innerHeight * 2 &&
      scrollPosition < window.innerHeight * 2.5
    ) {
      setCurrentSection("third");
    }
  }, [scrollPosition]);

  useLayoutEffect(() => {
    handleScroll();
  }, [scrollPosition, handleScroll]);

  return (
    <AboutScreenWrapper id="about">
      {scrollPosition < window.innerHeight * 3 && (
        <>
          <AboutScreenCircle $currentSection={currentSection} />
          <AboutScreenHeadline
            $isHigh
            style={{
              opacity: currentSection === "first" ? "1" : "0",
            }}
          >
            <p>
              I'm comfortable working with
              <br />
              <span>individual projects</span>
            </p>
          </AboutScreenHeadline>
          <AboutScreenHeadline
            style={{
              opacity: currentSection === "second" ? "1" : "0",
            }}
          >
            <p>
              or
              <br />
              <span>startups</span>
            </p>
          </AboutScreenHeadline>
          <AboutScreenHeadline
            style={{
              opacity: currentSection === "third" ? "1" : "0",
            }}
          >
            <p>
              or
              <br />
              <span>large companies.</span>
            </p>
          </AboutScreenHeadline>
        </>
      )}
    </AboutScreenWrapper>
  );
};

export default AboutScreen;
