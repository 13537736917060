import { FC } from "react";
import { NavigationItemWrapper } from "./NavigationItem.sc";

interface Props {
  text: string;
}

const NavigationItem: FC<Props> = ({ text }: Props) => {
  const handleClick = () => {
    const screen = document.getElementById(text.toLowerCase());
    if (screen !== null) {
      screen.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <NavigationItemWrapper onClick={handleClick}>
      <p>{text}</p>
      <p>{text}</p>
    </NavigationItemWrapper>
  );
};

export default NavigationItem;
