import { FC, useEffect, useRef, useState } from "react";
import {
  WorkItemWrapper,
  WorkItemTitle,
  WorkItemDate,
  WorkItemDescription,
  WorkItemTools,
  WorkItemTool,
} from "./WorkItem.sc";

interface Props {
  alternate?: boolean;
  company: string;
  date: string;
  description: string;
  jobTitle: string;
  tools: string[];
  scrollPosition: number;
}

interface State {
  isVisible: boolean;
}

const WorkItem: FC<Props> = ({
  alternate = false,
  company,
  date,
  description,
  jobTitle,
  tools,
  scrollPosition,
}: Props) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<State["isVisible"]>(false);

  useEffect(() => {
    if (!elementRef.current) return;

    if (
      elementRef.current.getBoundingClientRect().top <
      (window.innerHeight * 3) / 4
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [scrollPosition, elementRef]);

  return (
    <WorkItemWrapper
      $isVisible={isVisible}
      $isAlternate={alternate}
      ref={elementRef}
    >
      <WorkItemTitle $isAlternate={alternate}>
        <span>{company}</span> - {jobTitle}
      </WorkItemTitle>
      <WorkItemDate>{date}</WorkItemDate>
      <WorkItemDescription>{description}</WorkItemDescription>
      <WorkItemTools>
        {tools.map((tool) => (
          <WorkItemTool key={tool}>{tool}</WorkItemTool>
        ))}
      </WorkItemTools>
    </WorkItemWrapper>
  );
};

export default WorkItem;
