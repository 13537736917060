import { styled } from "styled-components";
import { colours } from "../../constants";

export const WorkItemWrapper = styled.div<{
  $isAlternate: boolean;
  $isVisible: boolean;
}>`
  opacity: ${({ $isVisible }) => ($isVisible ? "1" : "0")};
  width: 30rem;
  max-width: 40vw;
  background-color: ${colours.primaryDark};
  color: ${colours.primaryLight};
  position: absolute;
  z-index: 2;
  border-radius: 2.5rem;
  border: 0.5rem solid;
  border-color: ${({ $isAlternate }) =>
    $isAlternate ? colours.primaryDark : "transparent"};
  transition-duration: 600ms;

  @media (max-width: 960px) {
    width: 80vw;
    max-width: 80vw;
    position: initial;
    transform: translateX(-50%);
    margin: 2.5rem 0;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    background-color: ${colours.primaryDark};
    width: 2rem;
    height: 1rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    @media (max-width: 960px) {
      display: none;
    }
  }

  &:nth-of-type(even) {
    left: calc(100% + 1.5rem);

    &:after {
      right: 100%;
    }
  }

  &:nth-of-type(odd) {
    right: calc(100% + 1.5rem);

    &:after {
      left: 100%;
    }

    @media (max-width: 960px) {
      right: initial;
      left: calc(100% + 1.5rem);

      &:after {
        left: initial;
        right: 100%;
      }
    }
  }

  &:nth-of-type(1) {
    top: 2.5rem;
  }

  &:nth-of-type(2) {
    top: 15rem;
  }

  &:nth-of-type(3) {
    top: 30rem;
  }

  &:nth-of-type(4) {
    top: 40rem;
  }

  &:nth-of-type(5) {
    top: 50rem;
  }

  &:nth-of-type(6) {
    top: 65rem;
  }
`;

export const WorkItemTitle = styled.p<{ $isAlternate: boolean }>`
  line-height: 2rem;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  padding: 2rem;
  padding-bottom: 1rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  border-bottom: 1px solid
    ${({ $isAlternate }) =>
      $isAlternate ? colours.primaryDark : colours.primaryLight};

  background-color: ${({ $isAlternate }) =>
    $isAlternate ? colours.primaryLight : colours.primaryDark};
  color: ${({ $isAlternate }) =>
    $isAlternate ? colours.primaryDark : colours.primaryLight};

  > span {
    font-weight: bold;
    font-size: 2rem;
  }

  @media (max-width: 960px) {
    font-size: 1.75rem;
  }
`;

export const WorkItemDate = styled.p`
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 2rem;
  padding: 0 2rem;

  @media (max-width: 960px) {
    font-size: 1.25rem;
  }
`;

export const WorkItemDescription = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  padding: 0 2rem;

  @media (max-width: 960px) {
    font-size: 1.5rem;
  }
`;

export const WorkItemTools = styled.ul`
  padding: 0 2rem 1rem;
  font-size: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  font-style: italic;

  @media (max-width: 960px) {
    font-size: 1.5rem;
  }
`;

export const WorkItemTool = styled.li`
  margin-bottom: 0.5rem;
  width: 50%;
  text-align: center;
`;
