import { styled } from "styled-components";
import { colours } from "../../constants";

const baseAnimationTime = 1.5;

export const SkillsScreenWrapper = styled.div`
  background: ${colours.primaryLight};
  height: 200vh;
  position: relative;
`;

export const SkillsScreenBackground = styled.div<{ $isVisible: boolean }>`
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  overflow: hidden;
  opacity: ${({ $isVisible }) => ($isVisible ? "1" : "0")};
  transition-duration: 600ms;
`;

export const SkillsScreenBackgroundCube = styled.div`
  position: absolute;
  display: block;
  width: 2rem;
  height: 2rem;
  background: ${colours.primaryDark};
  animation-name: cubesAnimation;
  animation-duration: ${baseAnimationTime}s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  top: -15rem;
  border-radius: 25%;
  transition-duration: 300ms;

  &:nth-of-type(1) {
    left: 25%;
    width: 8rem;
    height: 8rem;
    animation-delay: 5s;
    animation-duration: ${baseAnimationTime * 2.1}s;
  }

  &:nth-of-type(2) {
    left: 10%;
    width: 2rem;
    height: 2rem;
    animation-delay: 4s;
    animation-duration: ${baseAnimationTime * 1.3}s;
  }

  &:nth-of-type(3) {
    left: 70%;
    width: 2rem;
    height: 2rem;
    animation-delay: 3s;
    animation-duration: ${baseAnimationTime * 1.8}s;
  }

  &:nth-of-type(4) {
    left: 40%;
    width: 6rem;
    height: 6rem;
    animation-delay: 1.1s;
    animation-duration: ${baseAnimationTime * 1.1}s;
  }

  &:nth-of-type(5) {
    left: 65%;
    width: 2rem;
    height: 2rem;
    animation-delay: 0.5s;
    animation-duration: ${baseAnimationTime * 2}s;
  }

  &:nth-of-type(6) {
    left: 75%;
    width: 7.5rem;
    height: 7.5rem;
    animation-delay: 6s;
    animation-duration: ${baseAnimationTime * 1.2}s;
  }

  &:nth-of-type(7) {
    left: 32%;
    width: 8rem;
    height: 8rem;
    animation-delay: 12s;
    animation-duration: ${baseAnimationTime * 1.5}s;
  }

  &:nth-of-type(8) {
    left: 50%;
    width: 2.5rem;
    height: 2.5rem;
    animation-delay: 7s;
    animation-duration: ${baseAnimationTime * 1.9}s;
  }

  &:nth-of-type(9) {
    left: 20%;
    width: 1.5rem;
    height: 1.5rem;
    animation-delay: 14s;
    animation-duration: ${baseAnimationTime * 1.8}s;
  }

  &:nth-of-type(10) {
    left: 85%;
    width: 10rem;
    height: 10rem;
    animation-delay: 5.2s;
    animation-duration: ${baseAnimationTime * 1.3}s;
  }
`;

export const SkillsScreenHeadline = styled.p<{
  $isVisible: boolean;
}>`
  font-size: 2rem;
  color: ${colours.primaryDark};
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;
  width: 80%;
  transform: translate(-50%, -50%);
  opacity: ${({ $isVisible }) => ($isVisible ? "1" : "0")};
  transition-duration: 600ms;

  &:first-of-type {
    > span {
      font-size: 6rem;
      display: block;
      transition-duration: 900ms;

      @media (max-width: 960px) {
        font-size: 4rem;
      }

      @media (max-width: 480px) {
        font-size: 3rem;
      }

      &:first-of-type {
        letter-spacing: -0.75rem;
        margin: 2rem 0.75rem 2rem 0;

        @media (max-width: 960px) {
          letter-spacing: -0.5rem;
          margin: 2rem 0.5rem 2rem 0;
        }
      }

      &:nth-of-type(2) {
        letter-spacing: 6rem;
        margin: 2rem -6rem 2rem 0;

        @media (max-width: 960px) {
          letter-spacing: 4rem;
          margin: 2rem -4rem 2rem 0;
        }

        @media (max-width: 480px) {
          letter-spacing: 1.25rem;
          margin: 2rem -1.25rem 2rem 0;
        }
      }
    }
  }
`;
