import { FC, useEffect, useRef, useState } from "react";
import {
  ContactScreenWrapper,
  ContactScreenTitle,
  ContactScreenLinks,
} from "./ContactScreen.sc";

const texts = ["work", "create", "chat", "build"];

interface State {
  text: string;
}

const ContactScreen: FC = () => {
  const textIndex = useRef<number>(0);
  const currentText = useRef<string>(texts[textIndex.current]);
  const spanRef = useRef<HTMLSpanElement>(null);
  const timeout = useRef<any>(null);

  const [text, setText] = useState<State["text"]>(texts[textIndex.current]);

  const removeText = () => {
    if (currentText.current.length > 0) {
      currentText.current = currentText.current.substring(
        0,
        currentText.current.length - 1
      );

      setText(currentText.current);

      clearTimeout(timeout.current);
      setTimeout(removeText, 50);
    } else {
      textIndex.current =
        textIndex.current + 1 > texts.length - 1 ? 0 : textIndex.current + 1;

      addText();
    }
  };

  const addText = () => {
    if (currentText.current.length < texts[textIndex.current].length) {
      currentText.current =
        currentText.current +
        texts[textIndex.current].charAt(currentText.current.length);

      setText(currentText.current);

      clearTimeout(timeout.current);
      setTimeout(addText, 50);
    } else {
      clearTimeout(timeout.current);
      setTimeout(removeText, 2000);
    }
  };

  useEffect(() => {
    timeout.current = setTimeout(() => {
      removeText();
    }, 2000);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  });

  return (
    <ContactScreenWrapper id="contact">
      <ContactScreenTitle>
        Want to <span ref={spanRef}>{text}</span>
        <br />
        with me?
      </ContactScreenTitle>
      <ContactScreenLinks>
        <a href="mailto:david.creaser@outlook.com">Email</a>
        <a
          href="https://www.linkedin.com/in/david-creaser/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
        <a
          href="https://github.com/davecreaser"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github
        </a>
        <a
          href={process.env.PUBLIC_URL + "/david_creaser_cv.pdf"}
          download="david_creaser_cv.pdf"
        >
          CV
        </a>
      </ContactScreenLinks>
    </ContactScreenWrapper>
  );
};

export default ContactScreen;
