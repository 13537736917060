import { styled } from "styled-components";
import { colours } from "../../constants";

export const WorkScreenWrapper = styled.div`
  width: 100vw;
  position: relative;
  color: ${colours.primaryDark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;

  @media (max-width: 960px) {
    padding: 10rem 5vw 0;
    box-sizing: border-box;
  }
`;

export const WorkScreenTitle = styled.p`
  font-size: 4rem;
  margin-bottom: 4rem;
  width: 80vw;
  text-align: center;
`;

export const WorkScreenTimeline = styled.div`
  height: 92.5rem;
  width: 1rem;
  background-color: ${colours.primaryDark};
  position: relative;

  @media (max-width: 960px) {
    position: initial;
    height: initial;
  }
`;

export const WorkScreenDegreeBox = styled.div`
  color: ${colours.primaryDark};
  border: 0.5rem solid ${colours.primaryDark};
  margin-bottom: 8rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  max-width: 30vw;
  text-align: center;

  @media (max-width: 960px) {
    max-width: 60vw;
  }

  > p:nth-of-type(1) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  > p:nth-of-type(2) {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  > p:nth-of-type(3) {
    font-size: 1.2rem;
    font-style: italic;
  }
`;

export const WorkScreenStatement = styled.p`
  font-size: 4rem;
  margin-bottom: 20rem;
  width: 80vw;
  text-align: center;
`;
