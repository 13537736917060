import { FC } from "react";
import NavigationItem from "../NavigattionItem/NavigationItem";
import { NavigationWrapper } from "./Navigation.sc";

const Navigation: FC = () => {
  return (
    <NavigationWrapper>
      <NavigationItem text="ABOUT" />
      <NavigationItem text="WORK" />
      <NavigationItem text="CONTACT" />
    </NavigationWrapper>
  );
};

export default Navigation;
