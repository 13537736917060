import { styled } from "styled-components";
import { colours } from "../../constants";

export const NavigationItemWrapper = styled.div`
  color: ${colours.primaryLight};
  width: 30%;
  position: relative;

  > p {
    position: absolute;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.6rem;
    height: 0.8rem;
    overflow: hidden;
    overflow: hidden;
    cursor: pointer;
    transition-duration: 300ms;
    opacity: 0.75;
    left: 50%;
    transform: translateX(-50%);
  }

  > p:nth-of-type(2) {
    transform: translate(-50%, 0.8rem);
    display: flex;
    align-items: flex-end;
  }

  &:hover {
    > P:first-of-type {
      transform: translateX(calc(-50% + 6px));
    }

    > p:nth-of-type(2) {
      transform: translate(calc(-50% - 6px), 0.8rem);
    }
  }
`;
