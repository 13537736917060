import { styled } from "styled-components";
import { colours } from "../../constants";

export const ContactScreenWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  color: ${colours.primaryLight};
  background-color: ${colours.primaryDark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  box-sizing: border-box;

  &:before {
    content: "";
    display: block;
    position: absolute;
    background-color: ${colours.primaryDark};
    width: 200%;
    height: 50%;
    transform: rotate(5deg);
    position: absolute;
    top: -6rem;
    left: -50%;
    z-index: 0;
  }
`;

export const ContactScreenTitle = styled.p`
  font-size: 12rem;
  text-align: right;
  height: 75vh;
  z-index: 2;
  width: 100%;

  @media (max-width: 960px) {
    font-size: 10rem;
  }
`;

export const ContactScreenLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25vh;
  width: 100%;
  max-width: 60rem;
  z-index: 2;

  a {
    color: ${colours.primaryLight};
    padding: 1rem;
    font-size: 2rem;
    text-decoration: none;
    transition-duration: 100ms;
    border-radius: 1rem;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 960px) {
    /* gap: 2.5rem; */
  }
`;
