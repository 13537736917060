import { throttle } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import AboutScreen from "./screens/AboutScreen/AboutScreen";
import LandingScreen from "./screens/LandingScreen/LandingScreen";
import SkillsScreen from "./screens/SkillsScreen/SkillsScreen";
import WorkScreen from "./screens/WorkScreen/WorkScreen";
import ContactScreen from "./screens/ContactScreen/ContactScreen";

interface State {
  isLoaded: boolean;
  scrollPosition: number;
}

const App: FC = () => {
  const [isLoaded, setIsLoaded] = useState<State["isLoaded"]>(false);
  const [scrollPosition, setScrollPosition] = useState<State["scrollPosition"]>(
    window.scrollY
  );

  const handleVideoLoaded = () => {
    setIsLoaded(true);
  };

  const handleScroll = useCallback(() => {
    setScrollPosition(window.scrollY);
  }, [setScrollPosition]);

  const handleScrollThrottled = throttle(handleScroll, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollThrottled);

    return () => {
      window.removeEventListener("scroll", handleScrollThrottled);
    };
  });

  return (
    <div
      style={{
        opacity: isLoaded ? "1" : "0",
        transitionDuration: "300ms",
        overflowX: "hidden",
      }}
    >
      <LandingScreen
        onVideoLoaded={handleVideoLoaded}
        scrollPosition={scrollPosition}
      />
      <AboutScreen scrollPosition={scrollPosition} />
      <SkillsScreen scrollPosition={scrollPosition} />
      <WorkScreen scrollPosition={scrollPosition} />
      <ContactScreen />
    </div>
  );
};

export default App;
