import { css, styled } from "styled-components";
import { colours } from "../../constants";
import { CurrentSection } from "./AboutScreen";

export const AboutScreenWrapper = styled.div`
  background: ${colours.primaryLight};
  height: 150vh;
  overflow: hidden;
  position: relative;
`;

export const AboutScreenCircle = styled.div<{
  $currentSection: CurrentSection;
}>`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 50%;
  background: ${colours.primaryDark};
  z-index: 1;
  transition-duration: 300ms;
  box-shadow: 0.2rem 0.4rem 0.8rem rgba(0, 0, 0, 0.8);

  ${({ $currentSection }) =>
    ($currentSection === "first" || !$currentSection) &&
    css`
      width: 95vh;
      height: 95vh;

      @media (max-width: 960px) {
        width: 95vw;
        height: 95vw;
      }
    `}

  ${({ $currentSection }) =>
    ($currentSection === "second" || $currentSection === "third") &&
    css`
      top: 50%;
      border-radius: 100% / 125% 125% 80% 80%;
      width: 30rem;
      height: 40rem;
    `}

  ${({ $currentSection }) =>
    $currentSection === "third" &&
    css`
      border-radius: 2rem;
      width: 80vw;
      height: 80vh;
    `}

    ${({ $currentSection }) =>
    $currentSection === "finish" &&
    css`
      border-radius: 2rem;
      width: 80vw;
      height: 80vh;
      opacity: 0;
      top: 50%;
    `}
`;

export const AboutScreenHeadline = styled.div<{
  $isHigh?: boolean;
}>`
  font-size: 4rem;
  color: ${colours.primaryLight};
  position: fixed;
  z-index: 2;
  top: ${({ $isHigh = false }) => ($isHigh ? "50%" : "50%")};
  left: 50%;
  width: 100vw;
  max-width: 75vw;
  transform: translate3d(-50%, -50%, 0);
  transition-duration: 300ms;
  text-align: center;

  span {
    font-size: 6rem;

    @media (max-width: 960px) {
      font-size: 4rem;
    }
  }

  @media (max-width: 960px) {
    font-size: 2rem;
  }

  @media (max-width: 720px) {
    font-size: 1.5rem;
  }
`;
