import { FC } from "react";
import WorkItem from "../../components/WorkItem/WorkItem";
import {
  WorkScreenWrapper,
  WorkScreenTitle,
  WorkScreenTimeline,
  WorkScreenDegreeBox,
  WorkScreenStatement,
} from "./WorkScreen.sc";

interface Props {
  scrollPosition: number;
}

const WorkScreen: FC<Props> = ({ scrollPosition }: Props) => {
  return (
    <WorkScreenWrapper id="work">
      <WorkScreenTitle>
        I have over 7 years of industry experience
      </WorkScreenTitle>
      <WorkScreenTimeline>
        <WorkItem
          scrollPosition={scrollPosition}
          company="Stitch"
          date="March 2022 - Present"
          description="Fashion tech startup. Working in the development team across both front & back end building digital showrooms and 3D design tools."
          jobTitle="Full stack engineer"
          tools={[
            "React",
            "Typescript",
            "GraphQL",
            "Golang",
            "Postgres",
            "Kubernetes",
            "Docker",
          ]}
        />
        <WorkItem
          scrollPosition={scrollPosition}
          company="Hatch"
          date="July 2019 - March 2022"
          description="Fashion tech startup. Grew the front end team from the ground up, building digital showrooms for big fashion brands."
          jobTitle="Front end developer"
          tools={[
            "React",
            "Typescript",
            "GraphQL",
            "Storybook",
            "Webpack",
            "Figma",
          ]}
        />
        <WorkItem
          scrollPosition={scrollPosition}
          alternate
          company="Project"
          date="May 2018 - June 2018"
          description="Beachside hotel in Poland. Built a new, modern website with booking engine in wordpress."
          jobTitle="Villa Baltic Hotel"
          tools={["Wordpress", "HBook Plugin"]}
        />
        <WorkItem
          scrollPosition={scrollPosition}
          company="Appical"
          date="April 2018 - June 2019"
          description="HR tech scaleup. Worked alongside mobile developers and a remote back end team building a progressive webapp."
          jobTitle="Front end developer"
          tools={["React", "Material UI", "Storybook", "AWS", "Circle CI"]}
        />
        <WorkItem
          scrollPosition={scrollPosition}
          alternate
          company="Project"
          date="December 2017 - January 2018"
          description="Dietician and holistic health expert. Built a new, modern website with animations in wordpress."
          jobTitle="Metoda JS"
          tools={["Wordpress", "HTML Canvas"]}
        />
        <WorkItem
          scrollPosition={scrollPosition}
          company="Salmon"
          date="September 2016 - March 2018"
          description="E-commerce consultancy. Built the newly redesigned Tommy Hilfiger and Calvin Klein online shops, alongside client-side designers and product managers."
          jobTitle="Graduate front end developer"
          tools={["JQuery", "SASS", "Grunt", "Websphere", "JSTL", "NodeJS"]}
        />
      </WorkScreenTimeline>
      <WorkScreenDegreeBox>
        <p>University of York</p>
        <p>Masters Degree</p>
        <p>Electronic Engineering with Music Technology Systems</p>
      </WorkScreenDegreeBox>
      <WorkScreenStatement>
        and i'm excited for what's next...
      </WorkScreenStatement>
    </WorkScreenWrapper>
  );
};

export default WorkScreen;
