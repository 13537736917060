import { styled } from "styled-components";
import { colours } from "../../constants";

export const LandingScreenWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  color: ${colours.primaryLight};
  background-color: black;
  z-index: 2;
`;

export const LandingScreenVideo = styled.video`
  width: 100vw;
  height: 100vh;
  background-color: black;
  object-fit: cover;
`;

export const LandingScreenBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 1;
  opacity: 0.7;
`;

export const LandingScreenIntro = styled.p`
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  max-width: 50%;
  z-index: 2;
  font-size: 8rem;
  text-align: right;
  font-weight: 600;
  letter-spacing: -0.2rem;
  opacity: 0.75;

  @media (max-width: 960px) {
    font-size: 6rem;
    max-width: 75%;
  }
`;
